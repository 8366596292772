import { Dispatch, SetStateAction } from 'react'
import { ID, Response } from '../../../../../_metronic/helpers'

export type ProjectReportsListModel = {
  id?: ID,
  reportType:string,
  reportNo?:string,
  submissionDate?:string,
  approvalDate?:string,
  reportingPeriodStartDate?:string
  reportingPeriodEndDate?:string
}


export type ProjectReportsModel = {
  id?: ID,
  uploadedFile?: Blob|any,
  fileName?:string,
  createdBy?: ID,
  updatedBy?: ID,
  reportType:string,
  reportNo?:string,
  submissionDate?:string,
  approvalDate?:string,
  reportingPeriodStartDate?:string
  reportingPeriodEndDate?:string
  files?:Array<FileInfoModel>,
  
}

export type ProjectReportsModelDTO = {
  id?: ID,
  fileName?:string,
  createdBy?: ID,
  updatedBy?: ID,
  reportType:string,
  reportNo?:string,
  submissionDate?:string,
  approvalDate?:string,
  reportingPeriodStartDate?:string
  reportingPeriodEndDate?:string
  files?:Array<FileInfoModel>,

}

export type  FileInfoModel = {
  fileId?: ID,
  fileName?:string,
  group?: number
  permissionStatus?:Array<number>
}

export type  FileInfoList = {
  fileId?: ID,
  fileName?: string,
  group?: number
}


export type ProjectReportsQueryResponse = Response<Array<ProjectReportsListModel>>

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  fileModelList: Array<FileInfoModel>
  setFileModelList: Dispatch<SetStateAction<Array<FileInfoModel>>>
  mainFile: FileInfoModel
  setMainFile: Dispatch<SetStateAction<FileInfoModel>>
  itemIdForDelete?: ID
  setItemIdForDelete: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  helpPageOn: boolean
  setHelpPageOn: Dispatch<SetStateAction<boolean>>

  isFileUploadSuccess?: boolean
  setIsFileUploadSuccess: Dispatch<SetStateAction<boolean | undefined>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDelete: () => { },
  isAllSelected: false,
  setFileModelList: () => { },
  fileModelList: [],
  mainFile: {
    fileId: "",
    fileName: "",
    group: 0
  },
  setMainFile: () => {},
  setHelpPageOn: () => {},
  helpPageOn: false,
  setIsFileUploadSuccess: () => { },
}


