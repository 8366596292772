import { FC, useEffect, useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { usersColumns } from './columns/_columns'
import { FileInfoModel } from '../../core/_models'
import { KTCardBody, KTSVG } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { deleteProjectReportFile, downloadFileById, getDownloadProjectReportFile } from '../../core/_requests'
import { Dropdown } from 'react-bootstrap'

type Props = {
  projectReportData?: any
}

const FilesTable: FC<Props> = ({ projectReportData }) => {
  console.log("filetable", projectReportData)
  const intl = useIntl()
  // const { fileModelList } = useListView()
  // const data = useMemo(() => fileModelList, [fileModelList])
  // const columns = useMemo(() => usersColumns, [])
  // const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
  //   columns,
  //   data,
  // })
  

  function downloadFile() {
    downloadFileById(projectReportData.id, projectReportData.fileName,intl)
  }

  function deleteItem() {
    deleteProjectReportFile(projectReportData.id)
    projectReportData.fileName=null
  }


  return (
    <>
      <div className='py-4'>
        <div className='table-responsive' style={{ height: "250px" }}>
          <table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th>{<FormattedMessage id='NAME' />}</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-500'>
              {projectReportData?.id != undefined && projectReportData?.fileName!=null && (
                <tr>
                  <td>{projectReportData.fileName}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='light'
                        className='btn btn-light btn-active-light-primary btn-sm'
                        id='dropdown-basic'
                      >
                        <FormattedMessage id="ACTIONS" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                      >
                        <Dropdown.Item
                          className='menu-item px-3 text-gray-600 fw-bold fs-7'
                          onClick={downloadFile}
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <span className='menu-link px-3'>
                            <FormattedMessage id="FILE.DOWNLOAD" />
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className='menu-item px-3 text-gray-600 fw-bold fs-7'
                          onClick={deleteItem}
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <span className='menu-link px-3'>
                            <FormattedMessage id="DELETE" />
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              )} {(projectReportData?.id == undefined && projectReportData?.fileName == null )&&
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORD.FOUND' })}
                    </div>
                  </td>
                </tr>
              }
              {(projectReportData?.id != undefined && projectReportData?.fileName == null )&&
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORD.FOUND' })}
                    </div>
                  </td>
                </tr>
              }
              {projectReportData?.id == undefined && projectReportData?.fileName!=null && (
                <tr>
                  <td>{projectReportData.fileName}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
    // <KTCardBody className='py-4'>
    //   <div className='table-responsive'>

    //     <table
    //       id='kt_table_users'
    //       className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
    //       {...getTableProps()}
    //     >
    //       <thead>
    //         <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
    //           {headers.map((column: ColumnInstance<FileInfoModel>) => (
    //             <CustomHeaderColumn key={column.id} column={column} />
    //           ))}
    //         </tr>
    //       </thead>
    //       <tbody className='text-gray-600 fw-500' {...getTableBodyProps()}>
    //         {rows.length > 0 ? (
    //           rows.map((row: Row<FileInfoModel>, i) => {
    //             prepareRow(row)
    //             return <CustomRow row={row} key={`row-${i}-${row.id}`} />
    //           })
    //         ) : (
    //           <tr>
    //             <td colSpan={7}>
    //               <div className='d-flex text-center w-100 align-content-center justify-content-center'>
    //               {intl.formatMessage({ id: 'NO.RECORD.FOUND' })}
    //               </div>
    //             </td>
    //           </tr>
    //         )}
    //       </tbody>
    //     </table>
    //   </div>
    // </KTCardBody>

    //  <KTCardBody className='py-4'>
    //   <div className='table-responsive'>
    //     <table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
    //       <thead>
    //         <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
    //           <th>{<FormattedMessage id = 'NAME'/>}</th>
    //         </tr>
    //       </thead>
    //       { projectReportData.id !=undefined &&
    //         <tbody className='text-gray-600 fw-500'>
    //         <tr>
    //           <td>{projectReportData.fileName}</td>
    //           <td>
    //             <a
    //               href='#'
    //               className='btn btn-light btn-active-light-primary btn-sm'
    //               data-kt-menu-trigger='click'
    //               data-kt-menu-placement='bottom-end'
    //             >
    //               {<FormattedMessage id="ACTIONS" />}
    //               <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
    //             </a>
    //             {(
    //               <div
    //                 className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
    //                 data-kt-menu='true'
    //               >
    //                   <div className='menu-item px-3'>
    //                     <a
    //                       className='menu-link px-3'
    //                       data-kt-users-table-filter='delete_row'
    //                      onClick={() => downloadFile()}
    //                     >
    //                       {"Download File"}
    //                     </a>
    //                   </div>
    //                 <div className='menu-item px-3'>
    //                   <a
    //                     className='menu-link px-3'
    //                     data-kt-users-table-filter='delete_row'
    //                    onClick={() => deleteItem()}
    //                   >
    //                     <FormattedMessage id="DELETE" />
    //                   </a>
    //                 </div>
    //               </div>
    //             )}
    //           </td>
    //         </tr>
    //       </tbody>
    //       }
    //     </table>
    //   </div>
    // </KTCardBody> 
  )
}

export { FilesTable }
