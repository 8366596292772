import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import { ProjectReportsModel, ProjectReportsModelDTO, ProjectReportsQueryResponse} from './_models'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify';

const API_URL = "https://shifatr.org/api/project-report"

const getProjectReports = (query: string): Promise<ProjectReportsQueryResponse> => {
  return axios
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<ProjectReportsQueryResponse>) => d.data)
}

const getProjectReportById = (id: ID, userId:any, userRole:any): Promise<ProjectReportsModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: Response<ProjectReportsModel>) => response.data)
}

const getDownloadProjectReportFile = (id: ID): Promise<ProjectReportsModel | undefined> => {
  return axios
    .get(`${API_URL}/downloadfile/${id}`)
    .then((response: Response<ProjectReportsModel>) => response.data)
}

const downloadFileById = (id: any, fileName: any, intlnss?:any) => {
  toast.info(intlnss.formatMessage({ id: 'DOWNLOAD_STARTED' }), {
    autoClose: false // Bu seçenek mesajın otomatik olarak kapanmasını engeller.
});

  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/downloadfile/${id}`,
  }).then((response) => {
    if (response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      toast.dismiss();
      link.click();
    }

  });
}


const createProjectReport = (ProjectReport: ProjectReportsModelDTO): Promise<ProjectReportsModelDTO | undefined> => {
  return axios
    .post(API_URL, ProjectReport)
    .then((response: Response<ProjectReportsModelDTO>) => response.data)
}

const updateProjectReport = (ProjectReport: ProjectReportsModelDTO): Promise<ProjectReportsModel | undefined> => {
  return axios
    .put(`${API_URL}`, ProjectReport)
    .then((response: Response<ProjectReportsModel>) => response.data)
}

const deleteProjectReport = (ProjectReportId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${ProjectReportId}`).then(() => {})
}

const deleteProjectReportFile = (ProjectReportId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/deletefile/${ProjectReportId}`).then(() => {})
}

const getHelpPdf = (): any => {
  return axios
    .get(`${API_URL}/getHelpDocument`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

const getChecklistAsPdf = (query: string) => {
  console.log(query)
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/pdfreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (query: string) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/excelreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}

const createProjectReportsFileUpload = (file: FormData): Promise<Response<ID> | undefined> => {
  return axios
    .post(`${API_URL}/file`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then((response: Response<ID>) => response)
  }

  const uploadChunks = (chunk: any, counter: number, fileName: string) => {
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("id", counter.toString());
    formData.append("fileName", fileName);
  
    return axios.post(`${API_URL}/uploadChunks`, formData)
      .then((response: any) => response);
  }
  const uploadComplete = (file: any) => {
    return axios.post(`${API_URL}/uploadComplete`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response);
  }
export {getDownloadProjectReportFile, downloadFileById, deleteProjectReportFile,createProjectReportsFileUpload,getProjectReports, getProjectReportById, deleteProjectReport, createProjectReport, updateProjectReport, getChecklistAsPdf, getChecklistAsExcel, getHelpPdf, uploadChunks, uploadComplete}
