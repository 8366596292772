import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { FILEGROUP, FILETYPES, getInstituionId, getUserId, getUserRole, isNotEmpty, QUERIES, SITETYPES, } from '../../../../../_metronic/helpers'
import { ProjectReportsModel, FileInfoModel, ProjectReportsModelDTO } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ProjectReportsLoading } from '../components/loading/ProjectReportsLoading'
import { createProjectReport, createProjectReportsFileUpload, updateProjectReport, uploadChunks, uploadComplete } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLang } from '../../../../../_metronic/i18n/Metronici18n'
import { createFileUpload } from '../../../file-upload-page/core/_requests'
import { FilesTable } from './table/FilesTable'
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  isLoading: boolean
  ProjectReport: ProjectReportsModel,
}

const chunkSize = 5 * 1024 * 1024;

const ProjectReportsEditModalForm: FC<Props> = ({ ProjectReport, isLoading }) => {
  if (ProjectReport.submissionDate) {
    ProjectReport.submissionDate = ProjectReport.submissionDate.substring(0, 10);
  }

  if (ProjectReport.approvalDate) {
    ProjectReport.approvalDate = ProjectReport.approvalDate.substring(0, 10);
  }
  if (ProjectReport.reportingPeriodStartDate) {
    ProjectReport.reportingPeriodStartDate = ProjectReport.reportingPeriodStartDate.substring(0, 10);
  }
  if (ProjectReport.reportingPeriodEndDate) {
    ProjectReport.reportingPeriodEndDate = ProjectReport.reportingPeriodEndDate.substring(0, 10);
  }


  const { refetch } = useQueryResponse()
  const userId = getUserId();
  const { setItemIdForUpdate, setFileModelList, fileModelList, setMainFile, mainFile, itemIdForUpdate, setIsFileUploadSuccess,  } = useListView()
  const role = getUserRole();
  const instituionId = getInstituionId();
  const [selectedReportingPeriodStartDate, setSelectedReportingPeriodStartDate] = useState(null);
  const [selectedReportingPeriodEndDate, setSelectedReportingPeriodEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedApprovedDate, setSelectedApprovedDate] = useState(null);

  let counter = 0;
  let beginingOfTheChunk = 0
  let endOfTheChunk = chunkSize
  let progress = 0;
  const [fileToBeUpload, setFileToBeUpload] = useState({})

  const resetChunkProperties = () => {
    progress = 0;
    beginingOfTheChunk = 0;
    endOfTheChunk = chunkSize
  }
  
  const [ProjectReportForEdit] = useState<ProjectReportsModel>({
    ...ProjectReport
  })


  useEffect(() => {
    if (itemIdForUpdate && ProjectReportForEdit.files !== null && ProjectReportForEdit.files !== undefined) {
      setFileModelList(ProjectReportForEdit.files)
      var mainFile = ProjectReportForEdit.files.filter(x => x.group !== 2)
      setMainFile(mainFile[0])
    }

  }, [ProjectReportForEdit, itemIdForUpdate, setFileModelList, setMainFile]);


  const enabledQueryAllContractList: boolean = (true)
  const intl = useIntl();
  let files: Array<any> = []
  let fileInfoModelList: Array<FileInfoModel> = []
  let attachments: Array<FileInfoModel> = []

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setFileModelList([])
    setMainFile({})
    setItemIdForUpdate(undefined)
  }

  const choiceSchema = () => {
    return Yup.object().shape({
      reportType: Yup.string()
        .max(50, "Max 50 character.")
        .required(<FormattedMessage id='AUTH.VALIDATION.REQUIRED_FIELD' /> as any),
    })
  }
  const editStakeholderSchema = choiceSchema();
  const formik = useFormik({
    initialValues: ProjectReportForEdit,
    validationSchema: editStakeholderSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {

        if (isNotEmpty(values.id)) {
          fileModelList && fileModelList.forEach(file => {
            file.fileId && fileInfoModelList.push(file)
          });
          let projectReportsModelDTO: ProjectReportsModelDTO = {
            id: values.id,
            reportType: values.reportType,
            reportNo: values.reportNo,
            submissionDate: values.submissionDate !== '' ? values.submissionDate : undefined,
            approvalDate: values.approvalDate !== '' ? values.approvalDate : undefined,
            reportingPeriodStartDate: values.reportingPeriodStartDate !== '' ? values.reportingPeriodStartDate : undefined,
            reportingPeriodEndDate: values.reportingPeriodEndDate !== '' ? values.reportingPeriodEndDate : undefined,
            files: fileInfoModelList,
          };
          await updateProjectReport(projectReportsModelDTO)
          if (formik.values.uploadedFile !== null && formik.values.uploadedFile !== undefined)

            try {
              const stringItemId= String(values.id)
              setFileToBeUpload(values.uploadedFile);
      
              if(values.uploadedFile.size > 5 * 1024 * 1024) {
                uploadChunkFile(values, String(values.id));
               }else {
                await sendFileUplaodRequst(formik.values.uploadedFile, values.id)
               } 
            } catch (ex) {
              console.error(ex);
              toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
            } 
        } else {


          let projectReportsModelDTO: ProjectReportsModelDTO = {
            reportType: values.reportType,
            reportNo: values.reportNo,
            submissionDate: values.submissionDate !== '' ? values.submissionDate : undefined,
            approvalDate: values.approvalDate !== '' ? values.approvalDate : undefined,
            reportingPeriodStartDate: values.reportingPeriodStartDate !== '' ? values.reportingPeriodStartDate : undefined,
            reportingPeriodEndDate: values.reportingPeriodEndDate !== '' ? values.reportingPeriodEndDate : undefined,
            files: fileInfoModelList,
          };
          var result = await createProjectReport(projectReportsModelDTO)
          values.id=String(result);
          if (formik.values.uploadedFile !== null && formik.values.uploadedFile !== undefined)
            try {
              const stringItemId= String(result)

              setFileToBeUpload(values.uploadedFile);
              if(values.uploadedFile.size > 5 * 1024 * 1024) {
                uploadChunkFile(values, stringItemId);
               }else {
                await sendFileUplaodRequst(formik.values.uploadedFile, values.id)
               } 
            } catch (ex) {
              console.error(ex);
              toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
            }
        }
      } catch (ex) {
        console.error(ex)
        toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
      } finally {
        setSubmitting(true)
        cancel(true)
        toast.success(intl.formatMessage({ id: 'SUCCESS_TOASTER' }));
      }
    },
  })

  const uploadChunkFile = async (values: any, result?: string) => {
    const _totalCount = values.uploadedFile.size % (chunkSize) == 0
        ? values.uploadedFile.size / chunkSize
        : Math.floor(values.uploadedFile.size / chunkSize) + 1;
    let chunkCount = _totalCount;

    if (counter === 0) {
      toast.info(intl.formatMessage({ id: 'UPLOAD_STARTED' }), {
        autoClose: false // Bu seçenek mesajın otomatik olarak kapanmasını engeller.
    });
  }

    if (counter <= chunkCount) {
      const stringItemId= String(result)
      var chunk = values.uploadedFile.slice(beginingOfTheChunk, endOfTheChunk);
      let formData = new FormData()
      formData.append("projectReportId", stringItemId)
      formData.append("uploadedFile", values.uploadedFile)
      formData.append("fileName", values.fileName)

      counter = counter + 1;
      try {
          await uploadChunks(chunk, counter, values.fileName).then(async (response: any) => {})
          .catch((error: any) => {
            toast.error(intl.formatMessage({ id: 'ERROR_UPLOAD_TOASTER' }));
          });   
          beginingOfTheChunk = endOfTheChunk;     
          endOfTheChunk = endOfTheChunk + chunkSize
          if (counter == chunkCount) {
            formData.delete("uploadedFile");
            uploadComplete(formData).then(async (response: any) => {
              if (response?.data.isSuccess == true) {
                toast.dismiss();
                setIsFileUploadSuccess(undefined)
                toast.success(intl.formatMessage({ id: 'SUCCESS_UPLOAD_TOASTER' }));
              }
              else {
                setIsFileUploadSuccess(false)
                toast.error(intl.formatMessage({ id: 'ERROR_UPLOAD_TOASTER' }));
              }
            }).catch((e: any) => {
              toast.error(intl.formatMessage({ id: 'ERROR_UPLOAD_TOASTER' }));              
            });
            resetChunkProperties();
          } else {
            var percentage = (counter / chunkCount) * 100;
            progress = percentage;
           await uploadChunkFile(values,result);
          }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const sendFileUplaodRequst = async (file: any, resultProjectReport?: any) => {
    let formData = new FormData()
    let fileInfo: FileInfoModel = {}
    formData.append("uploadedFile", file)
    formData.append("projectReportId", resultProjectReport)
    formData.append("fileName", file.name)
    // formData.append("fileTypeId", FILETYPES.MAF.id)
    var result = await createProjectReportsFileUpload(formData)
    fileInfo.fileId = result?.data;
    fileInfo.group = FILEGROUP.MAIN
    fileInfoModelList.push(fileInfo);
  }

  const sendFilesUplaodRequst = async (fileList: Array<any>) => {
    let i = 0;
    for (i; i < fileList.length; i++) {
      let formData = new FormData()
      let file: FileInfoModel = {}

      formData.append("uploadedFile", fileList[i])
      formData.append("fileName", fileList[i].name)
      // formData.append("fileTypeId", FILETYPES.MAF.id)
      var result = await createProjectReportsFileUpload(formData)
      file.fileId = result?.data;
      file.group = FILEGROUP.ATTACHMENT
      fileInfoModelList.push(file);
    }
  }

  const handleChange = async (e: any) => {
    var allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf', '.zip', '.rar'];

    var extension = null;
    if (e.target.files) {
      extension = e.target.files[0].name.substring(e.target.files[0].name.indexOf('.'));
    }

    if (!allowedExtensions.includes(extension)) {
      formik.setFieldValue("procurementFile", null)
      formik.setFieldValue("fileName", null)
      e.target.value = null;
      toast.warning(intl.formatMessage({ id: 'FILE_UPLOAD_TYPE' }));
    }
    else {
      formik.setFieldValue("uploadedFile", e.target.files[0])
      formik.setFieldValue("fileName", e.target.files[0].name)

      let file: FileInfoModel = {}
      file.group = FILEGROUP.MAIN
      file.fileName = e.target.files[0].name

      attachments = [...fileModelList];
      setFileModelList([])
      var index = attachments.indexOf(mainFile)
      if (index > -1) {
        await attachments.splice(index, 1);
      }

      attachments.push(file)
      setMainFile(file)
      setFileModelList(attachments)
    }
  }

  const handleChangeMultipleFile = async (e: any) => {

    if (!formik.values.id) {
      setFileModelList([])
    }
    else
      attachments = [...fileModelList];
    files.push(e.target.files)
    formik.setFieldValue("addAttachments", files[0])


    if (!formik.values.id && mainFile.fileName !== "" && mainFile !== null && mainFile !== undefined)
      attachments.push(mainFile)

    let i: number = 0
    for (i = 0; i < files[0].length; i++) {
      var isValid = validateFile(files[0][i]);
      if (isValid) {
        let file: FileInfoModel = {
          fileName: files[0][i].name,
          group: FILEGROUP.ATTACHMENT

        }
        attachments.push(file)
        setFileModelList(attachments)
      }
    }
  }
  const lang = useLang()

  const validateFile = (file: any) => {
    var allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf', '.zip', '.rar'];

    var extension = file.name.substring(file.name.indexOf('.'));

    if (!allowedExtensions.includes(extension)) {
      formik.setFieldValue("procurementFile", null)
      formik.setFieldValue("fileName", null)
      toast.warning(intl.formatMessage({ id: 'VALID_FILE_UPLOAD_TYPE' }) + file.name + intl.formatMessage({ id: 'FILE_UPLOAD_TYPE' }));
      return false;
    }

    return true;
  }

  return (
    <>
      <form id='kt_modal_add_Stakeholder_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>

              <div className="mb-10 col-6">
                <label className="required fw-bold fs-6 mb-2">
                  {<FormattedMessage id="REPORT.CHOOSE.TYPE" />}
                </label>
                <select
                  {...formik.getFieldProps("reportType")}
                  className="form-select form-select-solid fw-bolder"
                  name="reportType"
                  data-placeholder={intl.formatMessage({ id: "REPORT.CHOOSE.TYPE" })}
                  onChange={(e) => {
                    formik.setFieldValue("reportType", e.target.value)

                    // Set reportNo based on the selected reportType
                    if (e.target.value === "Expenditure Verification Report") {
                      formik.setFieldValue("reportNo", "EV")
                    } else if (e.target.value === "SGA") {
                      formik.setFieldValue("reportNo", "SGA")
                    } else if (e.target.value === "GA") {
                      formik.setFieldValue("reportNo", "GA")
                    } else {
                      formik.setFieldValue("reportNo", "")
                    }
                  }}
                >
                  <option value={""} key="">
                    {intl.formatMessage({ id: "REPORT.CHOOSE.TYPE" })}
                  </option>
                  <option value="Expenditure Verification Report" key="Expenditure Verification Report">
                    {intl.formatMessage({ id: "EXPENDITURE.VERIFICATION.REPORT" })}
                  </option>
                  <option value="SGA" key="SGA">SGA</option>
                  <option value="GA" key="GA">GA</option>
                  <option value="other" key="other">{intl.formatMessage({ id: "OTHER" })}</option>
                </select>
                {formik.touched.reportType && formik.errors.reportType && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.reportType}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="mb-10 col-6">
                <label className="fw-bold fs-6 mb-2">
                  {<FormattedMessage id="REPORT.NO" />}
                </label>
                <input
                  type="text"
                  {...formik.getFieldProps("reportNo")}
                  className="form-control form-control-solid fw-bolder"
                  name="reportNo"
                  placeholder={intl.formatMessage({ id: "REPORT.NO" })}
                />
              </div>




              {/* DATE PICKER START */}
              <style>{`
                  .custom-datepicker {
                    cursor: pointer;
                  }
                  .react-datepicker-wrapper {
                    display: flex;
                  }
                  .react-datepicker__input-container .react-datepicker__calendar-icon {
                    padding: 14px 0px 0px 10px;
                  }
                  .react-datepicker__view-calendar-icon input {
                    padding: 10px 10px 10px 25px;
                  }
                `}</style>
              <div className="mb-10 col-6">
                <label className="fw-bold fs-6 mb-2">
                  {<FormattedMessage id='SUBMISSION.DATE' />}
                </label>
                <div className="mb-3 mb-lg-0">
                  <DatePicker
                    showIcon
                    {...formik.values.submissionDate !== '0001-01-01' ? { ...formik.getFieldProps("submissionDate") } : null}
                    selected={selectedDate}
                    onChange={(date: any) => {
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      const formattedDate = `${year}-${month}-${day}`;
                      formik.setFieldValue("submissionDate", formattedDate);
                      setSelectedDate(date);
                    }}
                    placeholderText={intl.formatMessage({ id: 'SELECT.DATE' })}
                    dateFormat="yyyy-MM-dd"
                    className={clsx("custom-datepicker form-control form-control-solid", {
                      "is-invalid":
                        formik.touched.submissionDate && formik.errors.submissionDate,
                      "is-valid":
                        formik.touched.submissionDate && !formik.errors.submissionDate,
                    })}
                    autoComplete="off"
                    disabled={formik.isSubmitting || isLoading}
                  />
                  {/* {formik.touched.submissionDate && formik.errors.submissionDate && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.submissionDate}</span>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>

              <div className="mb-10 col-6">
                <label className="fw-bold fs-6 mb-2">
                  <FormattedMessage id='APPROVAL.DATE' />
                </label>
                <div className="mb-3 mb-lg-0">
                  <DatePicker
                    showIcon
                    {...formik.values.approvalDate !== '0001-01-01' ? formik.getFieldProps("approvalDate") : null}
                    selected={selectedApprovedDate}
                    onChange={(date: any) => {
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      const formattedDate = `${year}-${month}-${day}`;
                      formik.setFieldValue("approvalDate", formattedDate);
                      setSelectedApprovedDate(date);
                    }}
                    placeholderText={intl.formatMessage({ id: 'SELECT.DATE' })}
                    dateFormat="yyyy-MM-dd"
                    className="custom-datepicker form-control form-control-solid"
                    autoComplete="off"
                    disabled={formik.isSubmitting || isLoading}
                  />
                  {/* {formik.touched.approvalDate && formik.errors.approvalDate && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.approvalDate}</span>
                        </div>
                      </div>
                    )} */}
                </div>
              </div>
              <div className="mb-10 col-6">
                <label className="fw-bold fs-6 mb-2">
                  <FormattedMessage id='REPORTING.PERIOD.START.DATE' />
                </label>
                <div className="mb-3 mb-lg-0">
                  <DatePicker
                    showIcon
                    {...formik.values.reportingPeriodStartDate !== '0001-01-01' ? { ...formik.getFieldProps("reportingPeriodStartDate") } : null}
                    selected={selectedReportingPeriodStartDate}
                    onChange={(date: any) => {
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      const formattedDate = `${year}-${month}-${day}`;
                      formik.setFieldValue("reportingPeriodStartDate", formattedDate);
                      setSelectedReportingPeriodStartDate(date);

                      // End date'in minDate'ini start date'e ayarla
                      setSelectedReportingPeriodEndDate(null); // End date'i sıfırla
                    }}
                    placeholderText={intl.formatMessage({ id: 'SELECT.DATE' })}
                    dateFormat="yyyy-MM-dd"
                    className={clsx("custom-datepicker form-control form-control-solid", {
                      "is-invalid": formik.touched.reportingPeriodStartDate && formik.errors.reportingPeriodStartDate,
                      "is-valid": formik.touched.reportingPeriodStartDate && !formik.errors.reportingPeriodStartDate,
                    })}
                    autoComplete="off"
                    disabled={formik.isSubmitting || isLoading}
                  />
                </div>
              </div>

              <div className="mb-10 col-6">
                <label className="fw-bold fs-6 mb-2">
                  <FormattedMessage id='REPORTING.PERIOD.END.DATE' />
                </label>
                <div className="mb-3 mb-lg-0">
                  <DatePicker
                    showIcon
                    {...formik.values.reportingPeriodEndDate !== '0001-01-01' ? formik.getFieldProps("reportingPeriodEndDate") : null}
                    selected={selectedReportingPeriodEndDate}
                    onChange={(date: any) => {
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      const formattedDate = `${year}-${month}-${day}`;
                      formik.setFieldValue("reportingPeriodEndDate", formattedDate);
                      setSelectedReportingPeriodEndDate(date);
                    }}
                    placeholderText={intl.formatMessage({ id: 'SELECT.DATE' })}
                    dateFormat="yyyy-MM-dd"
                    className="custom-datepicker form-control form-control-solid"
                    autoComplete="off"
                    disabled={formik.isSubmitting || isLoading || !selectedReportingPeriodStartDate} // End date yalnızca start date seçildiğinde aktif
                    minDate={selectedReportingPeriodStartDate} // Start date'den daha önce bir tarih seçilmesine izin verme
                  />
                </div>
              </div>

              {/* DATE PICKER END */}

              <div className="mb-10 col-6">
                <label className='fw-bold fs-6 mb-2'> {<FormattedMessage id='MAF.FILE.UPLOAD' />}</label>
                <label
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': !ProjectReportForEdit.id && !formik.values.uploadedFile && formik.errors.uploadedFile },
                    {
                      'is-valid': ProjectReportForEdit.id || formik.values.uploadedFile
                    }
                  )}
                  htmlFor="file-input"
                  style={{ cursor: 'pointer' }}
                >
                  {formik.values.uploadedFile ? formik.values.uploadedFile.name : <FormattedMessage id='SELECT.FILE' />}
                </label>
                <input
                  id="file-input"
                  style={{ display: 'none' }}
                  placeholder='Uploaded File'
                  onChange={handleChange}
                  type='file'
                  multiple={false}
                  name='uploadedFile'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
              </div>

              {/* <div className="mb-10 col-6">
                <label className='fw-bold fs-6 mb-2'> {<FormattedMessage id='MAF.ADD.ATTACHMENT' />}</label>
                <label
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.addAttachments && formik.errors.addAttachments },
                    {
                      'is-valid': formik.touched.addAttachments && !formik.errors.addAttachments,
                    }
                  )}
                    htmlFor="add-attachments"
                    style={{ cursor: 'pointer' }}
                  >
                    { formik.values.addAttachments && formik.values.addAttachments.length > 0 && formik.values.addAttachments[0].name
                    ? formik.values.addAttachments[0].name
                    : <FormattedMessage id="SELECT.FILE" />}
                  </label>
                <input
                  id='add-attachments'
                  style={{ display: 'none' }}
                  placeholder='Uploaded File'
                  onChange={handleChangeMultipleFile}
                  type='file'
                  multiple
                  name='addAttachments'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />

                {formik.touched.addAttachments && formik.errors.addAttachments && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{""}</span>
                    </div>
                  </div>
                )}
              </div> */}

            </div>

            <div className="text-center pt-15">
              <button
                type="reset"
                onClick={() => cancel()}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                disabled={formik.isSubmitting || isLoading}
              >
                {<FormattedMessage id='DISCARD' />}
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
              // disabled={
              //   isLoading
              // }
              >
                <span className="indicator-label">{<FormattedMessage id='SUBMIT' />}</span>
                {/* {(formik.isSubmitting || isLoading) && ( */}
                {(formik.isSubmitting) && (
                  <span className="indicator-progress">
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-6'>
            <FilesTable projectReportData={formik.values} />
          </div>

        </div>


      </form>
      {(formik.isSubmitting || isLoading) && <ProjectReportsLoading />}
    </>
  )
}


export { ProjectReportsEditModalForm }