/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components";
import {
  getUserId,
  getUserRole,
  ID,
  KTSVG,
  QUERIES,
  ROLES,
} from "../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteProjectReport } from "../../core/_requests";
//import { deleteStakeholder } from "../../core/_requests";


type Props = {
  id: ID;
};

const ProjectReportsActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate, setItemIdForDelete } = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const userId = getUserId();
  const userRole = getUserRole();



  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  const deleteItem = () => {
    setItemIdForDelete(id)
  };
  const intl = useIntl()
  return (
    <>
      {(userRole != ROLES.CEB.name && userRole != ROLES.MOH_HGG.name && userRole != ROLES.SYGM_ID.name && userRole != ROLES.SYGM_BR.name
      && userRole != ROLES.SUMAF_Team.name && userRole != ROLES.EUD.name) &&
        <>
          <a
            href="#"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            {<FormattedMessage id="ACTIONS" />}
            <KTSVG
              path="/media/icons/duotune/arrows/arr072.svg"
              className="svg-icon-5 m-0"
            />
          </a>
          {/* begin::Menu */}
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            {/* begin::Menu item */}
            
            <div className="menu-item px-3">
              <a className="menu-link px-3" onClick={openEditModal}>
                <div style={{display:"flex", flexDirection: "column"}}>
                  <span style={{textAlign:"start"}}>{intl.formatMessage({ id: 'UPDATE' })} /</span> 
                  <span>
                  {intl.formatMessage({ id: 'FILE.DOWNLOAD' })}
                  </span>
                </div>
              </a>
            </div>


            {/* end::Menu item */}

            {/* begin::Menu item */}            
                  <div className='menu-item px-3'>
                    <a
                      className='menu-link px-3'
                      data-kt-users-table-filter='delete_row'
                      onClick={() => deleteItem()}
                    >
                      <FormattedMessage id="DELETE" />
                    </a>
                  </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </>
      }
    </>
  );
};

export { ProjectReportsActionsCell };
